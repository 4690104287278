/* dark theme */
:root,
html[data-theme="night"] {
	--mainBackground: #{$darkbg};
	--sidekickBackground: #{$secondaryDark};
	--lightPurpBackground: #{$offset};
	--main: #{$main};
	--mainOffSet: #{$offset};
	--sidekick: #{$sidekick};
	--triad: #{$accent};
	--dark: #{$secondaryDark};
	--darkToLight: rgb(170, 170, 170);
	--offWhite: #{$lightGray};
	--activeNode: #{$nodeActive};
	--node: #{$node};
	--unpublishedNode: #{$accent};
	--sText: #{$black};
	--text: #{$white};
	--light-text: rgb(219, 219, 219);
	--muted: rgba(255, 255, 255, 0.38);
	--disabled: rgba(255, 255, 255, 0.12);
	--hover: #{$darkHover};
	--muted-hover: #{$mutedDark};
	--links: #{$accent};
}

/* light theme */

:root,
html[data-theme="light"] {
	--mainBackground: #{$lightbg};
	--sidekickBackground: #{$white};
	--lightPurpBackground: #{$offset};
	--main: #{$main};
	--mainOffSet: #{$offset};
	--sidekick: #{$sidekick};
	--triad: #{$accent};
	--dark: #{$secondaryDark};
	--darkToLight: #{$secondaryDark};
	--offWhite: #{$white};
	--activeNode: #{$nodeActive};
	--node: #{$node};
	--unpublishedNode: #{$accent};
	--text: #{$black};
	--sText: #{$black};
	--light-text: rgb(110, 110, 110);
	--muted: #{$mutedDark};
	--disabled: rgba(0, 0, 0, 0.24);
	--hover: #{$lightHover};
	--muted-hover: #{$lightMutedHover};
	--links: #{$main};
}

/* sunset theme */
:root,
html[data-theme="sunset"] {
	--mainBackground: #{$bg};
	--irisBackground: #{$iris};
	--sidekickBackground: #{$white};
	--lightPurpBackground: #{$lightPurp};
	--main: #{$main};
	--mainOffSet: #{$pink};
	--sidekick: #{$deepPurp};
	--triad: #{$accent};
	--dark: #{$secondaryDark};
	--darkToLight: #{$secondaryDark};
	--offWhite: #{$EGray};
	--activeNode: #{$nodeActive};
	--node: #{$node};
	--unpublishedNode: #{$accent};
	--sText: #{$white};
	--text: #{$black};
	--light-text: rgb(110, 110, 110);
	--muted: #{$mutedDark};
	--disabled: rgba(0, 0, 0, 0.288);
	--hover: #{$lightHover};
	--muted-hover: #{$lightMutedHover};
	--links: #{$main};
}