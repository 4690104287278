@mixin placeholder($color, $element: 0) {
	@if $element == 0 {
		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $color;
			opacity: 1; /* Firefox */
		}
		:-ms-input-placeholder, /* Internet Explorer 10-11 */
    ::ms-input-placeholder {
			/* Microsoft Edge */
			color: $color;
		}
	}
	@if $element != 0 {
		#{$element} {
			&::placeholder {
				color: $color;
				opacity: 1;
			}
			&:ms-input-placeholder,
			&::ms-input-placeholder {
				color: $color;
			}
		}
	}
}

@include placeholder(var(--darkToLight) !important, placeholder-dark-to-light);
@include placeholder(var(--darkToLight) !important, placeholder-dark);
@include placeholder(var(--dark) !important, textarea);
@include placeholder(var(--text) !important);

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
	text-align: center;
}
/* Tree Items */
.item[data-depth="1"] {
	padding-left: 10px !important;
	margin-right: -10px !important;
}

.item[data-depth="2"] {
	padding-left: 16px !important;
	margin-right: 16px !important;
}

.item[data-depth="3"] {
	padding-left: 25px !important;
	margin-right: 25px !important;
}

.item[data-depth="4"] {
	padding-left: 40px !important;
	margin-right: 40px !important;
}

.item[data-depth="5"] {
	padding-left: 60px !important;
	margin-right: 60px !important;
}

.item[data-depth="6"] {
	padding-left: 86px !important;
	margin-right: 86px !important;
}

.item[data-depth="7"] {
	padding-left: 109px !important;
	margin-right: 109px !important;
}

.item[data-depth="8"] {
	padding-left: 150px !important;
	margin-right: 150px !important;
}

.item[data-depth="9"] {
	padding-left: 200px !important;
	margin-right: 200px !important;
}

.material-icons.vertical-toggle:hover {
	cursor: pointer;
}

.dropdown.inline-dropdown {
	display: inline;
}

// .item {
// 	margin-bottom: 5px;
// }

/* General - Bootstrap Overrides */

.s-text {
	color: var(--sText);
}

.App-header {
	background-color: var(--dark);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: var(--text);
}

.App-link {
	color: #61dafb;
}

.background {
	background-color: var(--mainBackground);
}

.sunset-background {
	background: linear-gradient(180deg, #ee9dac 0%, #fdf2f4 100%);
}

.irisBackground {
	background: var(--irisBackground);
}

.grid {
	max-width: fit-content !important;
	min-width: 40% !important;
}

.btn:disabled {
	background-color: var(--disabled) !important;
}

.col {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.map {
	height: 600px;
}

.form-control {
	border-color: var(--muted) !important;
	font-size: 14px !important;

	&::placeholder {
		color: var(--darkToLight) !important;
	}
}

.is-invalid.form-control {
	border-color: #b00020 !important;
}
.border-gray.is-invalid {
	border: #b00020 1px solid !important;
}
.border-focus-gray.is-invalid {
	border: #b00020 1px solid !important;
}

.nav-link:hover {
	background-color: var(--hover) !important;
}

.navdrawer-nav .nav-link.active {
	background-color: rgba(98, 0, 238, 0.12) !important;
	color: var(--text) !important;
}

.navdrawer-nav .nav-link.disabled {
	background-color: transparent !important;
	color: rgba(0, 0, 0, 0.38) !important;
}

.nav-tabs .nav-link.active {
	color: var(--text) !important;
	background-color: rgba(98, 0, 238, 0.12) !important;
}

.nav-tabs .nav-link:focus {
	background-color: rgba(98, 0, 238, 0.12) !important;
}

.btn-white:hover {
	color: var(--main) !important;
}

/* React Flow */

.card {
	background-color: var(--mainBackground) !important;
	color: var(--text);
}

.react-flow {
	background-color: var(--sidekickBackground) !important;
}

.floatingedges .react-flow__handle {
	opacity: 0;
}

// Hides Attribution but it is a little hacky, since they ask for a subscription in order to remove it
// .react-flow__attribution {
// 	display: none;
// }

.react-flow__node {
	border: none;
}

/* Password Strength Bar */
.progress-bar::after {
	content: none !important;
}

/* SnackBar */
.snackbared {
	animation: smallSnack linear 0.25s;
}

@keyframes smallSnack {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
	}
}

/* Modal */
.modal {
	display: contents !important;
}

div#modal {
	position: absolute;
	z-index: 90;
}

div#error_modal {
	position: absolute;
	z-index: 110;
}

div#saving_modal {
	position: absolute;
	z-index: 100;
}

/* Drawer */
.navdrawer-top .navdrawer-content {
	top: 0 !important;
	bottom: auto !important;
	width: 100% !important;
	height: 30% !important;
	max-width: 100% !important;
}

.navdrawer-bottom .navdrawer-content {
	bottom: 0 !important;
	top: auto !important;
	max-width: 100% !important;
	width: 100% !important;
	height: 30% !important;
	transform: none !important;
}

.navdrawer-open-temporary .doc-navbar-temporary {
	transition-duration: 0.225s;
	transition-property: margin-left;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
	margin-left: 15.5rem;
}

/* Competencies Nav */

.nav-tabs {
	box-shadow: none !important;
}
.nav-item {
	content: "" !important;
}
.nav-tabs-left {
	border-radius: 16px 0px 0px 0px !important;
}
.navdrawer-header {
	background-color: var(--mainBackground) !important;
	color: var(--text);
}

/* Forms */
.invalid-feedback {
	display: block !important;
}

textarea {
	resize: none !important;
	height: auto !important;
	display: block !important;
	padding: 8px !important;
}

input {
	color: var(--darkToLight) !important;
}

.input-w-no-bg {
	color: var(--text) !important;
}

/* Scroll Bar */

/* ::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	color: #d6dee1;
	width: 10px;
	border-radius: 0px 20px 20px 0px;
	border: 6px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
	color: #a8bbbf !important;
	cursor: pointer !important;
} */

.btn-outline-* {
	border: none !important;
}

/* Theme Transition */

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
	transition: all 750ms !important;
	transition-delay: 0 !important;
}
