@use "sass:math";

$utilities: (
	"width": (
		"prefix": "w",
		"values": (
			"none": 0,
			"screen": 100vw,
			"full": 100%,
			"half": 50%,
			"1-4": 25%,
			"2-4": 50%,
			"3-4": 75%,
		),
	),
	"height": (
		"prefix": "h",
		"values": (
			"none": 0,
			"screen": 100vh,
			"full": 100%,
			"half": 50%,
			"90": 90vh,
			"80": 80vh,
			"10": 10%,
			"100px": 100px,
			"130px": 130px,
		),
	),
	"font-weight": (
		"prefix": "font",
		"values": (
			"100": $weight,
			"200": $weight * 2,
			"300": $weight * 3,
			"400": $weight * 4,
			"500": $weight * 5,
			"600": $weight * 6,
			"700": $weight * 7,
			"800": $weight * 8,
			"900": $weight * 9,
		),
	),
	"font-size": (
		"prefix": "text",
		"values": (
			"sm": $text,
			"base": $text + 2,
			"lgish": $text + 4,
			"lg": $text + 6,
			"xl": $text + 12,
			"2xl": $text + 18,
			"3xl": $text + 24,
			"4xl": $text + 32,
			"5xl": $text + 40,
			"6xl": $text + 48,
		),
	),
	"max-height": (
		"prefix": "max-h",
		"values": (
			"90": 90px,
			"100": 100px,
			"8v": 8vh,
		),
	),
);

// utility generation
@each $property, $map in $utilities {
	$prefix: map-get($map, "prefix");
	$values: map-get($map, "values");

	@each $k, $v in $values {
		@if ($k == "default") {
			.#{$prefix} {
				#{$property}: $v;
			}
		} @else {
			.#{$prefix}-#{$k} {
				#{$property}: $v;
			}
		}
	}
}

// Custom Extended Utils
.bg-color-iris {
	background-color: #a5a6f6;
}
.color-blue {
	color: #648fff;
}
.crossed-out {
	color: #dc267f;
	text-decoration: line-through;
}
.overflow-x-none {
	overflow-x: hidden;
}
.px-half {
	padding: 0px 0.15rem;
}
.my-half {
	margin: 0.15rem 0px;
}
.pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default !important;
}
.overflow-hidden {
	overflow: hidden !important;
}
.overflow-y {
	overflow-y: auto !important;
}

.min-w-50p {
	min-width: 50% !important;
}
.w-min-content {
	width: min-content !important;
}

.min-h-screen {
	min-height: 100vh !important;
}
.btn-outline-none {
	box-shadow: none !important;
}
.no-hover.btn:hover {
	background-image: none !important;
}
.z-40 {
	z-index: 40 !important;
}

.bottom-0 {
	bottom: 0 !important;
}
.font-sans {
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: 600;
}
.font-robo {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
}

.font-bold {
	font-weight: bold !important;
}
.color-purple {
	color: var(--main) !important;
}
.bg-color-purple {
	background-color: var(--main) !important;
}
.bg-color-light-purple {
	background-color: var(--sidekick) !important;
}
.bg-offset-purple {
	background-color: var(--mainOffSet) !important;
}
.bg-light-offset-purple {
	background-color: var(--lightPurpBackground) !important;
}
.color-dark {
	color: var(--dark) !important;
}
.bg-color-dark {
	background-color: var(--dark) !important;
}
.text {
	color: var(--text) !important;
}
.text-purple {
	color: var(--main) !important;
}
.light-text {
	color: var(--light-text) !important;
}
.muted {
	color: var(--muted) !important;
}
.bg-figma-gray {
	background: #e0e0e0 !important;
}
.bg-sidekick {
	background-color: var(--sidekick) !important;
}
.bg-secondary-white {
	background-color: var(--sidekickBackground) !important;
}
.bg-none {
	background: none !important;
}
.triad {
	color: var(--triad);
}
.link {
	color: var(--links) !important;
}
.selected-bg {
	background-color: #9656f0;
}
.bg-light-themed {
	background-color: var(--offWhite) !important;
}
.bg-muted {
	background-color: var(--muted) !important;
}
.bg-red {
	background-color: red;
}
.border-gray {
	border: #aaaaaa 1px solid !important;
}
.border-gray:hover {
	border: #adadad 1px solid !important;
}
.border-focus-primary:focus-visible {
	outline: var(--main) 1px solid !important;
}
.border-top-rounded {
	border-radius: 9px 9px 0px 0px !important;
}
.border-bottum-rounded {
	border-radius: 0px 0px 9px 9px !important;
}
.border-top-left-rounded {
	border-radius: 9px 0px 0px 0px !important;
}
.border-top-right-rounded {
	border-radius: 0px 9px 0px 0px !important;
}
.border-rounded {
	border-radius: 9px 9px 9px 9px !important;
}
.border-rounded-sm {
	border-radius: 4px 4px 4px 4px !important;
}
.border-none {
	border: none !important;
}
.border-1 {
	border: 1px solid var(--darkToLight) !important;
}
.border-w-none {
	border-width: unset !important;
}
.border-w-1 {
	border-width: 1px !important;
}
.border-w-2 {
	border-width: 2px !important;
}
.border-bottom-1 {
	border-bottom: 1px solid var(--darkToLight) !important;
}
.border-top-1 {
	border-top: 1px solid var(--darkToLight) !important;
}
.border-s {
	border-color: var(--sText) !important;
}
.-pr-1 {
	padding-right: -1em;
}
.pr-7 {
	padding-right: 7em;
}
.-ml-2 {
	margin-left: -2em;
}
.-ml-3 {
	margin-left: -3em;
}
.-mt-1 {
	margin-top: -1rem;
}
.-mt-2 {
	margin-top: -2rem;
}
.-mt-3 {
	margin-top: -3rem;
}
.line-height-none {
	line-height: unset !important;
}
.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis !important;
}
button:focus {
	outline: none !important;
}

.rounded-input {
	border: 1px solid var(--text);
	border-radius: 80px !important;
}
.navdrawer-backdrop {
	z-index: unset !important;
}

.tracing-1 {
	letter-spacing: 0.1rem;
}
.tracing-2 {
	letter-spacing: 0.2rem;
}
.tracing-half {
	letter-spacing: 0.05rem;
}
.text-shadow-1 {
	text-shadow: 0.5px 0.5px #44444485;
}
.top-1 {
	top: 0.1rem;
}
.right-1 {
	right: 0.1rem;
}
.right-2 {
	right: 0.2rem;
}
