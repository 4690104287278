/* TreeCard */
.tree-card {
	overflow-y: auto;
	max-width: 100%;
	min-width: 380px;
	max-height: 675px; /* 675px */
	min-height: 468px;
	left: 0px;
	top: 108px;

	background: var(--sidekickBackground);
	/* border: 2px solid #000000; */
	box-sizing: border-box;
	border-radius: 16px;
	.competency-container {
		width: calc(100% - 14px);
	}
}
.competency-row-container {
	.top-margin-6 {
		margin-top: 6px;
	}
}
/* Inside Auto Layout */
.auto-layout {
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 16px 0px;
}

/* listItemWithMenu */

/* Auto Layout */
.list-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	position: static;
	width: 212px;
	height: 20px;
	left: 32px;
	top: 56px;
}
/* Inside Auto Layout */
.list-item-layout {
	flex: none;
	order: 2;
	flex-grow: 0;
	margin: 4px 0px;
}
/* Selected */
.selected-bg.selected-item {
	background-color: var(--main);
	font-size: 14.5px;
	color: white;
	box-sizing: border-box;
	min-width: 300px;
	transition: ease-in-out 0.06s;
}

.competency-alignment-wrap .selected-bg.selected-item {
	color: $black;
	background-color: var(--offWhite);
}

/* Competencies */

.competency-tree-item {
	position: absolute;
	visibility: hidden;
	width: 197.86px;
	height: 23px;
	left: 5px;
	top: 10px;

	font-size: 18px;
	line-height: 23px;
}

.create-child-competency-btn {
	margin: auto;
	margin-left: 8px;
	padding: 0 16px;
	border: 2px groove rgba(0, 0, 0, 0.5);
	border-radius: 16px;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 17.6px;
	text-wrap: nowrap;
	height: 24px;
	width: 136px;
	.material-icons.outline-black {
		margin-left: 10px;
		font-size: 12px;
		font-weight: 600;
		padding-right: 0.5px;
	}
}

.material-icons.outline-black {
	border: 1px solid black;
}

/* TreeHeader */

.tree-header {
	position: static;
	max-width: 100%;
	min-width: 388px;
	max-height: 120px;
	min-height: 95px;
	left: 0px;
	top: 0px;

	background: var(--sidekickBackground);
	/* border: 1px solid #000000; */
	box-sizing: border-box;
	border-radius: 16px;

	/* Inside Auto Layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	/* margin: 16px 0px; */
}
.tree-header-dropdown {
	width: 822px;
	height: 32px;
}

.btn-border {
	background: var(--sidekickBackground);
	border: 2px solid #000000;
	box-sizing: border-box;
	border-radius: 40px;
}

/* Competency Map */

.competency-graph-popover.custom-popover {
	border: 1.5px solid var(--mainBackground);
	padding: 16px;
	border-radius: 10px;
	position: absolute;
	z-index: 4;
}
.competency-graph-popover.custom-popover .published-unpublished-indicator::before {
	height: 10px;
	width: 10px;
	display: inline-block;
	content: "";
	margin-right: 10px;
}
#competency_graph_map_flow .react-flow__pane {
	cursor: move;
	cursor: all-scroll;
}
#competency_graph_map_flow .react-flow__node {
	cursor: pointer !important;
}
.react-flow,
.react-flow__renderer.react-flow__zoompane {
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
#competency_graph_map_flow {
	position: relative;
}

.competency-graph-popover.custom-popover .published-unpublished-indicator.published::before {
	background-color: #45e074;
}
.competency-graph-popover.custom-popover .published-unpublished-indicator.unpublished::before {
	background-color: #d93f8e;
}

.competency-map {
	/* width: 1023px; */

	/* border: 2px solid #000000; */
	box-sizing: border-box;
	border-radius: 16px !important;
	background-color: var(--sidekickBackground) !important;
}

.competency-nav {
	/* border: 2px solid #000000 !important;
	border-bottom: unset !important;
	box-sizing: border-box !important; */
	border-radius: 16px;
}

/* NavBar */
.figma-navbar {
	height: 44px;
	background: var(--dark); /* #0D1F49 */
	border: 1px solid var(--dark);
}
/* Competencies Editor */

.competencies-editor {
	max-width: 100%;
	min-width: 500px;
	/* width: 1023px; */
	max-height: 395px; /* 800px */

	background: var(--mainOffSet);
	border-radius: 16px;
	/* border: 2px solid #000000; */
}

.competencey-scope-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px 0px 8px;

	max-width: 100%;
	min-width: 330px;

	/* height: 718px; */
	max-height: 365px;

	background: var(--offWhite);
	/* border: 1px solid #000000; */
	box-sizing: border-box;
	border-radius: 10px;
}

/* Modal */
.modal-component {
	position: relative;
	padding: 15px;
	box-sizing: border-box;
	min-height: 10%;
	min-width: 15%;
	max-height: 80%;
	max-width: 80%;
	box-shadow:
		0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	border-radius: 4px;
}
.small-modal {
	width: 400px;
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/* background-color: rgba(51, 51, 51, 0.3; */
	background-color: rgba(82, 45, 150, 0.5);
	backdrop-filter: blur(2px);
	opacity: 0;
	transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
	transition-delay: 200ms;
	display: flex;
	align-items: center;
	justify-content: center;
}

.backdrop .modal-content {
	transform: translateY(100px);
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 0;
}
.backdrop.active {
	transition-duration: 250ms;
	transition-delay: 0ms;
	opacity: 1;
}
.backdrop.active.modal-content {
	transform: translateY(0);
	opacity: 1;
	transition-delay: 150ms;
	transition-duration: 350ms;
}

.scope-view-content {
	max-width: 100%;
	min-width: 65%;
}

/* Competency Creator/Editor */
.editor {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	overflow-y: auto;

	position: static;
	width: 800px;
	max-height: 95%;

	min-height: 624px;
	left: 18px;
	top: 948px;

	border-radius: 10px;
	box-sizing: border-box;
}

// Login Screen

.login-background {
	background: conic-gradient(from 0deg at 50% 50%, #2e3191 0deg, #eaeaf7 360deg);
}

.login-form-container {
	// position: absolute;
	width: 475px;
	height: 645px;
	// left: 1067px;
	// top: 197px;
}

.login-input-text {
	color: rgba(0, 0, 0, 0.404) !important;
}

.login-input {
	border-width: 1px 1px 1px !important;
	border-radius: 8px !important;
	border-color: rgba(0, 0, 0, 0.863) !important;
	color: black !important;
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
}

.login-welcome {
	// position: absolute;
	min-width: 343px;
	min-height: 146px;
	// left: 590px;
	// top: 129px;
}

.btn-login {
	background-color: #2e3191e5 !important;
	color: #eaeaf7 !important;

	&:disabled {
		background-color: rgba(0, 0, 0, 0.16) !important;
	}
}

/*   Document Editor -- needs work for responsiveness  */
.document-editor {
	display: flex;
	flex-direction: column;
	padding: 16px;

	width: 1464px;
	max-width: 1464px;
	min-width: auto;

	height: 648px;
	min-height: 648px;
	max-height: fit-content;
}

.document-column-wrapper {
	width: 1400px;
	max-width: 1400px;
	min-width: 800px;

	height: 550px;
	min-height: 550px;
	max-height: fit-content;
	overflow-y: auto;

	box-sizing: border-box;
}

/* ------------ Competencies to Standards Modal --------------------*/
.aligned-competencies-box {
	width: fit-content;
	min-height: min-content;
	max-height: 100px;
}
/* ------------ People Id ------------ */
.people-id {
	padding: 0px 4px;
	background: #0077fa;
	border-radius: 2px;
	height: min-content;
}
